<template>
    <div class="comment_list">
        <div v-for="comment in commentData" :key="`comment${comment.wr_id}`" class="detail_wrap">
            <div class="info" >
                <div class="profile_img" @click="openMemberInfoModal(comment.Writer.mb_no)">
                    <img :src="returnImageUrl(comment.Writer)">
                </div>
                <div class="profile_wrap">
                    <div class="pw-info">
                        <span class="name">{{comment.Writer.mb_name}}</span>
                        <span class="job">{{comment.Writer.mb_job}}</span>
                    </div>
                    <span class="date">{{returnDate(comment.created_at)}}</span>
                </div>
                <el-dropdown trigger="click" class="more_btn" @command="handleCommand"  v-show="!checkHasUpdate(comment) && UserInfo.mb_type != 3">
                    <span class="el-dropdown-link">menu</span>
                    <el-dropdown-menu slot="dropdown" class="comment-menu" v-if="checkMyComment(comment)">
<!--                    <el-dropdown-menu slot="dropdown" class="comment-menu" v-if="checkMyBoard() || checkMyComment(comment)">-->
                        <el-dropdown-item :command="{key : 'update', data: comment}">수정</el-dropdown-item>
                        <el-dropdown-item :command="{key : 'delete', data: comment}">삭제</el-dropdown-item>
                    </el-dropdown-menu>
                    <el-dropdown-menu slot="dropdown" class="comment-menu" v-else-if="checkIsAdmin()" @command="handleCommand">
                        <el-dropdown-item :command="{key : 'delete', data: comment}">삭제</el-dropdown-item>
                    </el-dropdown-menu>
                    <el-dropdown-menu slot="dropdown" class="comment-menu" v-else-if="!checkIsCenter()">
                        <el-dropdown-item :command="{key : 'declaration', data: comment}">신고</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="comment_input_box" v-if="checkHasUpdate(comment)">
                <textarea
                        class="write"
                        style="resize: none;"
                        maxlength="1000"
                        v-model="comment.wr_content"
                        placeholder="(권리침해, 욕설, 특정 대상을 비하하는 내용, 청소년에게 유해한 내용 등을 게시할 경우 운영정책과 이용약관 및 관련 법률에 의해 제재될 수 있습니다. 댓글 작성 시 배려와 책임을 담아 주세요.)"></textarea>
                <div class="write-info clear">
                    <div class="length_box">
                        <span class="length">{{comment.wr_content.length}}</span>/1000
                    </div>
                    <div class="button-wrap">
                        ※ 이 댓글에 대한 법적 책임은 작성자에게 귀속됩니다.
                        <button class="comment_btn" @click="runUpdateFunc(comment)" :disabled="comment.wr_content === ''">등록</button>
                        <button class="comment_btn" @click="cancelUpdateComment(comment)">취소</button>
                    </div>
                </div>
            </div>
            <div class="content" v-html="replaceBr(comment.wr_content)" v-else></div>

        </div>
    </div>
</template>
<script>
    import {mapGetters, mapState} from "vuex";
    import EventBus from "@/utils/event-bus";
    import VuxAlert from "@/mixins/vux-alert";
    import BoardLikeComponent from "@/components/board/BoardLikeComponent";
    import util from "@/mixins/util";
    import imageOption from "@/mixins/imageOption";

    export default {
        name: 'CommentListDefaultLayout',
        mixins: [VuxAlert, imageOption],
        components: {
            BoardLikeComponent,
        },
        // inject: ['commentDelete', 'commentBlind'],
        inject: {
            commentDelete: {
                default: () => {},
            },
            commentBlind: {
                default: () => {},
            },
            checkMyBoard: {
                default: () => {}
            },
            commentSetData: {
                default: () => {},
            },
            commentUpdate: {default: () => {}}
        },
        props: {
            commentData: {
                // type: Array,
                default: () => {
                    return []
                },
            },
        },
        data() {
            return {
            }
        },
        created() {
            EventBus.$on('commentListLayoutUpdate', () => {this.$forceUpdate()})
        },
        mounted() {

        },
        beforeDestroy() {
            EventBus.$off('commentListLayoutUpdate')
        },
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            }),
        },
        methods: {
            returnDate(date) {
                if(!date) {
                    return ''
                }
                return this.$moment(date).format('YYYY.MM.DD.HH:mm')
            },
            replaceBr(content) {
                if(util.isEmpty(content)) {
                    return '';
                }
                return content.replace(/(\n|\r\n)/g, '<br>');
            },
            checkMyComment(comment) {
                return this.UserInfo.mb_no === comment.Writer.mb_no;
            },
            checkIsAdmin() {
                return this.UserInfo.mb_type === 0;
            },
            checkIsCenter() {
                return this.UserInfo.mb_type === 3;
            },
            checkHasUpdate(comment) {
                return !util.isEmpty(comment.hasUpdate) && comment.hasUpdate === true;
            },
            openDeleteConfirm(comment) {
                this.createConfirm({
                    title: '',
                    content: '정말 삭제하시겠습니까?',
                    confirmText: '확인',
                    cancelText: '취소',
                    confirm: () => {
                        if(!util.isEmpty(this.commentDelete)) {
                            this.commentDelete(comment.wr_id);
                        }
                    }
                })
            },
            openDeclarationConfirm(comment) {
                this.declarateConfirm({
                    comment: comment,
                    confirm: this.commentBlind
                })
            },
            declaration(comment, reason) {
                this.createDeclaration(comment, reason);
            },
            openMemberInfoModal(mb_no) {
                /*this.$modal.show(MemberInfoModal, {
                    memberKey: 1,
                })*/
                EventBus.$emit('openMemberInfoModal', mb_no)
            },
            returnImageUrl(writer) {
                return writer.hasOwnProperty('Profile') && writer.Profile.length > 0 ? `${writer.Profile[0].org_url}?${this.setImageOptions(80, 80, 'png')}` : '';
            },
            handleCommand(command) {
                console.log(command)
                let key = command.key;
                let data = command.data;
                if(key === 'delete') {
                    this.openDeleteConfirm(data);
                }else if(key === 'declaration') {
                    this.openDeclarationConfirm(data);
                }else if(key === 'update') {
                    data.wr_content_before = data.wr_content;
                    data.hasUpdate = true;
                    // this.setCommentHadUpdate(data);
                    this.$forceUpdate();
                }
            },
            runUpdateFunc(comment) {
                this.commentUpdate(comment);
            },
            cancelUpdateComment(comment) {
                /*let commentIndex = this.commentData.findIndex(com => com.wr_id === comment.wr_id);
                if(commentIndex > -1) {
                    let commentList = this.commentData;
                    commentList[commentIndex].wr_content_before = commentList[commentIndex].wr_content;
                    commentList[commentIndex].hasUpdate = true;
                }*/
                comment.wr_content = comment.wr_content_before;
                comment.hasUpdate = false;
                this.$forceUpdate();
            },

        },
        watch: {},
    }
</script>